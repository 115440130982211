/* eslint-disable prefer-const */
/**
 * @remarks This is a very thin fetch wrapper that is similar in spirit to `axios`, `redaxios, `ky`, etc.
 * It's a slightly trimmed down version of the battle/production-tested `fetchBaseQuery` from @reduxjs/toolkit.
 */

import { isPlainObject } from "../isPlainObject";

import { joinUrls } from "./utils";
import type { MaybePromise, Override } from "./typeHelpers";

export type ResponseHandler =
  | "content-type"
  | "json"
  | "text"
  | ((response: Response) => Promise<any>);

type CustomRequestInit = Override<
  RequestInit,
  {
    headers?:
      | Headers
      | string[][]
      | Record<string, string | undefined>
      | undefined;
  }
>;

export interface FetchArgs extends CustomRequestInit {
  url: string;
  params?: Record<string, any>;
  body?: any;
  responseHandler?: ResponseHandler;
  validateStatus?: (response: Response, body: any) => boolean;
}

/**
 * A mini-wrapper that passes arguments straight through to
 * {@link [fetch](https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API)}.
 * Avoids storing `fetch` in a closure, in order to permit mocking/monkey-patching.
 */
const defaultFetchFn: typeof fetch = (
  input: RequestInfo | URL,
  init?: RequestInit,
) => fetch(input, init);

const defaultValidateStatus = (response: Response) =>
  response.status >= 200 && response.status <= 299;

const defaultIsJsonContentType = (headers: Headers) =>
  headers.get("content-type")?.trim().startsWith("application/json") ?? false;

export type QueryReturnValue<T = unknown, E = unknown> =
  | {
      error: E;
      data?: undefined;
    }
  | {
      error?: undefined;
      data: T;
    };

export type BaseQueryFn<Args = any, Result = unknown, Error = unknown> = (
  args: Args,
) => Promise<QueryReturnValue<Result, Error>>;

export type FetchBaseQueryParsingError = {
  /**
   * * `"PARSING_ERROR"`:
   *   An error happened during parsing.
   *   Most likely a non-JSON-response was returned with the default `responseHandler` "JSON",
   *   or an error occurred while executing a custom `responseHandler`.
   **/
  status: "PARSING_ERROR";
  originalStatus: number;
  data: string;
  error: string;
};
export type FetchBaseQueryFetchError = {
  /**
   * * `"FETCH_ERROR"`:
   *   An error that occurred during execution of `fetch` or the `fetchFn` callback option
   **/
  status: "FETCH_ERROR";
  data?: undefined;
  error: string;
};
export type FetchBaseQueryCustomError = {
  /**
   * * `"CUSTOM_ERROR"`:
   *   A custom error type that you can return from your `queryFn` where another error might not make sense.
   **/
  status: "CUSTOM_ERROR";
  data?: unknown;
  error: string;
};
export type FetchBaseQueryBaseError = {
  /**
   * * `number`:
   *   HTTP status code
   */
  status: number;
  data: unknown;
};

export type FetchBaseQueryError =
  | FetchBaseQueryBaseError
  | FetchBaseQueryFetchError
  | FetchBaseQueryParsingError
  | FetchBaseQueryCustomError;

function stripUndefined(obj: any) {
  if (!isPlainObject(obj)) {
    return obj;
  }
  const copy: Record<string, any> = { ...obj };
  for (const [k, v] of Object.entries(copy)) {
    if (typeof v === "undefined") delete copy[k];
  }
  return copy;
}

export type FetchBaseQueryArgs = {
  baseUrl?: string;
  prepareHeaders?: (headers: Headers) => MaybePromise<Headers>;
  fetchFn?: (
    input: RequestInfo,
    init?: RequestInit | undefined,
  ) => Promise<Response>;
  paramsSerializer?: (params: Record<string, any>) => string;
  /**
   * By default, we only check for 'application/json' as the content type for json. If you need to support another format, you can pass
   * in a predicate function for your given api to get the same automatic stringifying behavior
   * @example
   * ```ts
   * const isJsonContentType = (headers: Headers) => ["application/vnd.api+json", "application/json"].includes(headers.get("content-type")?.trim());
   * ```
   */
  isJsonContentType?: (headers: Headers) => boolean;
  /**
   * Defaults to `application/json`;
   */
  jsonContentType?: string;
} & RequestInit;

/**
 * This is a very small wrapper around fetch that aims to simplify requests.
 *
 * @example
 * ```ts
 * const baseQuery = fetchBaseQuery({
 *   baseUrl: 'https://api.your-really-great-app.com/v1/',
 * })
 * ```
 *
 * @param {string} baseUrl
 * The base URL for an API service.
 * Typically in the format of https://example.com/
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/API/Headers
 *
 * @param {(input: RequestInfo, init?: RequestInit | undefined) => Promise<Response>} fetchFn
 * Accepts a custom `fetch` function if you do not want to use the default on the window.
 * Useful in SSR environments if you need to use a library such as `node-fetch` or `cross-fetch`
 *
 * @param {(params: Record<string, unknown>) => string} paramsSerializer
 * An optional function that can be used to stringify querystring parameters.
 *
 * @param {(headers: Headers) => boolean} isJsonContentType
 * An optional predicate function to determine if we `JSON.stringify()` should be called on the `body` arg of `FetchArgs`
 * @param {string} jsonContentType Defaults to `application/json`. Used when automatically setting the content-type header for a request with a body that does not have an explicit content-type header.
 */
export function fetchBaseQuery<ResponseType>({
  baseUrl,
  prepareHeaders = (x) => x,
  fetchFn = defaultFetchFn,
  paramsSerializer,
  isJsonContentType = defaultIsJsonContentType,
  jsonContentType = "application/json",
  ...baseFetchOptions
}: FetchBaseQueryArgs = {}): BaseQueryFn<
  string | FetchArgs,
  ResponseType,
  FetchBaseQueryError
> {
  if (typeof fetch === "undefined" && fetchFn === defaultFetchFn) {
    console.warn(
      "Warning: `fetch` is not available. Please supply a custom `fetchFn` property to use `fetchBaseQuery` on SSR environments.",
    );
  }
  return async (arg) => {
    let {
      url,
      method = "GET" as const,
      headers = new Headers({}),
      body = undefined,
      params = undefined,
      responseHandler = "content-type" as const,
      validateStatus = defaultValidateStatus,
      ...rest
    } = typeof arg == "string" ? { url: arg } : arg;
    let config: RequestInit = {
      ...baseFetchOptions,
      method,
      body,
      ...rest,
    };

    config.headers = await prepareHeaders(new Headers(stripUndefined(headers)));

    // Only set the content-type to json if appropriate. Will not be true for FormData, ArrayBuffer, Blob, etc.
    const isJsonifiable = (body: any) =>
      typeof body === "object" &&
      (isPlainObject(body) ||
        Array.isArray(body) ||
        typeof body.toJSON === "function");

    if (!config.headers.has("content-type") && isJsonifiable(body)) {
      config.headers.set("content-type", jsonContentType);
    }

    if (body && isJsonContentType(config.headers)) {
      config.body = JSON.stringify(body);
    }

    if (params) {
      const divider = ~url.indexOf("?") ? "&" : "?";
      const query = paramsSerializer
        ? paramsSerializer(params)
        : new URLSearchParams(stripUndefined(params));
      url += divider + query;
    }

    url = joinUrls(baseUrl, url);

    const request = new Request(url, config);

    let response;
    try {
      response = await fetchFn(request);
    } catch (e) {
      return { error: { status: "FETCH_ERROR", error: String(e) } };
    }

    let resultData: any;
    let responseText = "";
    try {
      let handleResponseError;
      await Promise.all([
        handleResponse(response, responseHandler).then(
          (r) => (resultData = r),
          (e) => (handleResponseError = e),
        ),
      ]);
      if (handleResponseError) throw handleResponseError;
    } catch (e) {
      return {
        error: {
          status: "PARSING_ERROR",
          originalStatus: response.status,
          data: responseText,
          error: String(e),
        },
      };
    }

    return validateStatus(response, resultData)
      ? {
          data: resultData,
        }
      : {
          error: {
            status: response.status,
            data: resultData,
          },
        };
  };

  async function handleResponse(
    response: Response,
    responseHandler: ResponseHandler,
  ) {
    if (typeof responseHandler === "function") {
      return responseHandler(response);
    }

    if (responseHandler === "content-type") {
      responseHandler = isJsonContentType(response.headers) ? "json" : "text";
    }

    if (responseHandler === "json") {
      const text = await response.text();
      return text.length ? JSON.parse(text) : null;
    }

    return response.text();
  }
}
