import type * as React from "react";

export interface ConditionalWrapperProps<Children extends React.ReactNode> {
  children: Children;
  condition: boolean;
  wrapper: (children: Children) => React.ReactNode;
  falsyWrapper?: (children: Children) => React.ReactNode;
}

/**
 * If the condition provided evaluates to true, the children will be wrapped in the wrapper function.
 *
 * @example
 * <ConditionalWrapper
 *   condition={hideLabel}
 *   wrapper={(children) => <VisuallyHidden>{children}</VisuallyHidden>}
 * >
 *   <FormLabel htmlFor={field.name} marginInlineEnd={2}>
 *     {label}
 *   </FormLabel>
 * </ConditionalWrapper>
 */
export const ConditionalWrapper = <Children extends React.ReactNode>({
  children,
  condition,
  wrapper,
  falsyWrapper,
}: ConditionalWrapperProps<Children>) => (
  <>
    {condition
      ? wrapper(children)
      : falsyWrapper
        ? falsyWrapper(children)
        : children}
  </>
);
