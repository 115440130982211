import {
  type ComponentSingleStyleConfig,
  popperCSSVars,
} from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

import typography from "../foundations/typography";

export default {
  baseStyle: (props) => ({
    // There's no other way to style the tooltip arrow besides targeting the CSS var;
    // this is how Chakra styles it in their own tooltip component.
    [popperCSSVars.arrowBg.var]: mode(
      "var(--chakra-colors-tooltip-bg)",
      "var(--chakra-colors-gray-800)",
    )(props),
    bg: mode("tooltip-bg", "gray.800")(props),
    ...typography.textStyles.tooltip,
    color: "gray.100",
  }),
} satisfies ComponentSingleStyleConfig;
