import * as React from "react";
import { useLocale } from "@react-aria/i18n";
import { useDateFieldState } from "@react-stately/datepicker";
import type {
  DateSegment as DateSegmentType,
  DateFieldState,
} from "@react-stately/datepicker";
import { useDateField, useDateSegment } from "@react-aria/datepicker";
import { createCalendar } from "@internationalized/date";
import { Box, chakra } from "@chakra-ui/react";
import type { BoxProps, UseDisclosureReturn } from "@chakra-ui/react";
import type { DatePickerAria } from "@react-aria/datepicker";

import { noop } from "../../utils";

export function DateField({
  onOpen,
  placeholder,
  ...props
}: DatePickerAria["fieldProps"] & {
  onOpen: UseDisclosureReturn["onOpen"];
  placeholder?: string;
}) {
  const { locale } = useLocale();
  const state = useDateFieldState({
    ...props,
    locale,
    createCalendar,
  });

  const ref = React.useRef(null);
  const { fieldProps } = useDateField(props, state, ref);

  return (
    <Box
      {...fieldProps}
      ref={ref}
      display="flex"
      aria-label="date"
      alignItems="center"
    >
      {state.value ? (
        state.segments.map((segment, i) => (
          <DateSegment
            key={i}
            segment={segment}
            state={state}
            onOpen={state.isDisabled ? noop : onOpen}
          />
        ))
      ) : (
        <chakra.span
          color={state.isDisabled ? "gray.400" : "gray.600"}
          onClick={state.isDisabled ? undefined : onOpen}
          textStyle="paragraph"
        >
          {placeholder ?? "Select date"}
        </chakra.span>
      )}
    </Box>
  );
}

export const StyledField = React.forwardRef<HTMLDivElement, BoxProps>(
  function StyledField({ children, ...otherProps }, ref) {
    return (
      <Box
        aria-label="day"
        position="relative"
        background="white"
        border="1px solid"
        borderColor="gray.300"
        rounded="base"
        transition="all 200ms"
        display="flex"
        alignItems="center"
        py=".4rem"
        pl={3}
        maxHeight="32px"
        _hover={{
          borderColor: "gray.400",
        }}
        _focusVisible={{
          outline: "none",
          borderColor: "blue.300",
          shadow: "outline",
        }}
        _dark={{
          background: "transparent",
          borderColor: "gray.700",
          _hover: {
            borderColor: "gray.600",
          },
        }}
        tabIndex={0}
        {...otherProps}
        ref={ref}
      >
        {children}
      </Box>
    );
  },
);

function DateSegment({
  onOpen,
  segment,
  state,
}: {
  onOpen: UseDisclosureReturn["onOpen"];
  segment: DateSegmentType;
  state: DateFieldState;
}) {
  const ref = React.useRef(null);
  const { segmentProps } = useDateSegment(segment, state, ref);

  return (
    <Box
      {...segmentProps}
      ref={ref}
      style={{
        ...segmentProps.style,
        fontVariantNumeric: "tabular-nums",
        boxSizing: "content-box",
      }}
      textAlign="end"
      outline="none"
      rounded="base"
      onKeyDown={(e) => e.preventDefault()}
      onClick={onOpen}
      textStyle="paragraph"
      color={
        segment.isPlaceholder || state.isDisabled
          ? "gray.500"
          : !segment.isEditable
            ? "gray.600"
            : "gray.900"
      }
      _dark={{
        color:
          segment.isPlaceholder || state.isDisabled
            ? "gray.300"
            : !segment.isEditable
              ? "gray.200"
              : "gray.100",
      }}
    >
      {segment.text}
    </Box>
  );
}
