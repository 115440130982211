const preloadErrorSentinel = Symbol("__vite_preload_error_sentinel__");

export function attachPreloadErrorHandler() {
  // https://vite.dev/guide/build#load-error-handling
  window.addEventListener("vite:preloadError", (event) => {
    Object.assign(event.payload, {
      [preloadErrorSentinel]: true,
    });

    window.location.reload();
  });
}

export function isPreloadError(
  error: Error,
): error is Error & { [preloadErrorSentinel]: true } {
  return preloadErrorSentinel in error;
}
