import { useAuthPermissions } from "./cloudApi/cloudComponents";
import { hasPermission } from "@stordco/cloud-utils";
import type {
  PermissionsList,
  ResourcePermissions,
} from "./cloudApi/cloudSchemas";

export interface UseHasPermissionsOptions {
  app: string;
  organization: string;
  resourcePath: string[];
  permission: string;
  enabled?: boolean;
}

export function useHasPermissions({
  app,
  organization,
  resourcePath,
  permission,
  enabled,
}: UseHasPermissionsOptions):
  | {
      status: "error";
    }
  | {
      status: "pending";
    }
  | {
      status: "success";
      result: boolean;
    } {
  const permissionsQuery = useAuthPermissions<{
    data: {
      permissions: PermissionsList | ResourcePermissions;
    };
  }>(
    {
      pathParams: {
        app,
        organization,
      },
    },
    {
      enabled,
    },
  );

  if (enabled === false) {
    return {
      status: "pending",
    };
  }

  if (permissionsQuery.isError) {
    return {
      status: "error",
    };
  }

  if (permissionsQuery.isPending) {
    return {
      status: "pending",
    };
  }

  const permissions = permissionsQuery.data?.data?.permissions ?? {};

  return {
    status: "success",
    result: hasPermission(permissions, resourcePath, permission),
  };
}
