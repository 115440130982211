import { createContext, useContext, useRef, useMemo } from "react";
import type { ReactNode } from "react";
import qs from "query-string";

import { useAuthentication } from "../../hooks/useAuthentication";

import { createApi } from "./createApi";
import type { FetchBaseQueryArgs } from "./fetchBaseQuery";

export type Api = ReturnType<typeof createApi>;

type ApiProviderContextValue = {
  client: Api;
  jsonApiClient: Api;
  cloudClient: Api;
  headers: {
    get: (key: string) => string | undefined;
    getAll: () => Record<string, string>;
    set: (key: string, value: string) => void;
  };
};

const ApiProviderContext = createContext<ApiProviderContextValue | null>(null);

/**
 * This is a pre-configured `ApiProvider` that is meant to be used within AuthenticationProvider, like the below:
 *
 * ```ts
 *   <AuthenticationProvider>
 *    <ApiProvider baseUrl="https://api.yourdomain.com/">
 *       <App />
 *    </ApiProvider>
 *   </AuthenticationProvider>
 * ```
 *
 * For other implementations, just modify this ApiProvider as you see fit or do something else altogether. Please note,
 * you can not mix and mismatch providers from fe-components that rely on each other, alongside of your own. If you are
 * going the totally custom route, you should handle your own equivalent of useConfig, useAuthentication, etc etc. Mixing
 * providers/hooks from fe-components with your own can and will lead to undefined context values. When in doubt,
 * just roll your own providers/hooks and use this file as a boilerplate example.
 *
 * @param clientOverrides - Overrides for the `createApi` function in the case the default behavior does not work as expected.
 * The only thing that should really change is `paramsSerializer` depending on the API.
 */
export const ApiProvider = ({
  children,
  baseUrl,
  cloudUrl,
}: {
  children: ReactNode;
  baseUrl: string;
  cloudUrl?: string;
}) => {
  const { getAccessTokenSilently } = useAuthentication();

  const customHeadersRef = useRef<Record<string, string>>({});

  const [client, cloudClient, jsonApiClient] = useMemo(() => {
    const baseArgs: FetchBaseQueryArgs = {
      baseUrl,
      prepareHeaders: async (headers) => {
        const token = await getAccessTokenSilently();

        headers.set("authorization", `Bearer ${token}`);

        Object.entries(customHeadersRef.current).forEach(([key, value]) => {
          headers.set(key, value);
        });

        return headers;
      },
      paramsSerializer: qs.stringify,
    };

    // Set up the Orion V2+ API client
    const client = createApi({ ...baseArgs });

    // Setup the Cloud API client
    const cloudClient = createApi({
      ...baseArgs,
      baseUrl: cloudUrl,
    });

    // Setup the Orion V1 API client that uses JSONAPI
    const jsonApiClient = createApi({
      ...baseArgs,
      jsonContentType: "application/vnd.api+json",
    });

    return [client, cloudClient, jsonApiClient];
  }, [baseUrl, cloudUrl, getAccessTokenSilently]);

  const headers = useMemo(
    (): ApiProviderContextValue["headers"] => ({
      get: (key) => customHeadersRef.current[key],
      getAll: () => customHeadersRef.current,
      set: (key, value) => {
        customHeadersRef.current[key] = value;
      },
    }),
    [],
  );

  return (
    <ApiProviderContext.Provider
      value={{
        client,
        jsonApiClient,
        cloudClient,
        headers,
      }}
    >
      {children}
    </ApiProviderContext.Provider>
  );
};

const useApiContext = () => {
  const apiContext = useContext(ApiProviderContext);

  if (!apiContext) {
    throw new Error(
      "`useApiContext` can only be used inside an `<ApiProvider>`",
    );
  }

  return apiContext;
};

/**
 * @returns An API instance that automatically sets the correct headers
 */
export const useApi = () => useApiContext().client;

export const useCloudApi = () => useApiContext().cloudClient;

export const useApiHeaders = () => useApiContext().headers;

/**
 * @returns An API instance that automatically sets the correct authorization and JSONAPI headers
 */
export const useJsonApi = () => useApiContext().jsonApiClient;
