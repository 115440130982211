import { Card as ChakraCard, type CardProps } from "@chakra-ui/react";
import type { ComponentRef } from "react";
import { forwardRef } from "react";

import { CardHeader, CardHeaderWithCenteredRightElement } from "./CardHeader";
import { CardPaddedContent } from "./CardPaddedContent";
import { CardTitle } from "./CardTitle";
import { CardActions } from "./CardActions";
import { LinkBoxCard } from "./LinkBoxCard";

const CardComponent = forwardRef<ComponentRef<typeof ChakraCard>, CardProps>(
  function Card(props, ref) {
    return <ChakraCard ref={ref} {...props} />;
  },
);

export * from "./LinkBoxCard";
export * from "./CardListItem";
export * from "./CardDataList";

export const Card = Object.assign(CardComponent, {
  Header: CardHeader,
  HeaderWithCenteredRightElement: CardHeaderWithCenteredRightElement,
  Title: CardTitle,
  PaddedContent: CardPaddedContent,
  Actions: CardActions,
  LinkBox: LinkBoxCard,
});
