import {
  useLocation,
  Navigate,
  useNavigate,
  useBeforeUnload,
  Link as ReactRouterLink,
} from "react-router-dom";
import { useMemo, useEffect, useCallback, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Flex,
  Divider,
  ModalFooter,
  Button,
  FormLabel,
  FormErrorMessage,
  FormControl,
  useToast,
  Text,
  Link,
} from "@chakra-ui/react";
import {
  RHFInput,
  RHFSelect,
  useApi,
  useUser,
  useIsAdmin,
  Select,
  isBaseError,
  ErrorPage,
} from "@stordco/fe-components";
import type { Control } from "react-hook-form";
import { useController } from "react-hook-form";
import { Controller } from "react-hook-form";
import { useForm } from "react-hook-form";
import type { Channel } from "../hooks";
import { useQuery, useMutation, useSuspenseQuery } from "@tanstack/react-query";
import { NetworkSelect } from "../features/Admin/NetworkSelect";

interface FieldValues {
  network: {
    network_id: string;
    tenant_id: string;
  };
  display_name: string;
  source_location_id: string;
  sales_channel: string;
}

export function ConnectShopify() {
  const location = useLocation();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );

  const nonce = params.get("nonce");

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FieldValues>();

  const network = watch("network");

  const api = useApi();

  const channelsParams = {
    status: "active",
    network_id: network?.network_id,
  };

  const channelsQuery = useQuery({
    queryKey: [
      "Channels",
      network?.tenant_id,
      network?.network_id,
      channelsParams,
    ],
    queryFn: () =>
      api<{ data: Channel[] }>({
        url: "/v1/channels",
        headers: {
          "tenant-id": network?.tenant_id,
          "x-network-id": network?.network_id,
        },
        params: { limit: 500, ...channelsParams },
      }),
    select: ({ data }) => data,
    enabled: Boolean(network),
  });

  const connectionQuery = useSuspenseQuery({
    queryKey: ["ShopifyConnections", nonce],
    queryFn: () =>
      api<{
        id: string;
        customer_connection_id: string | null;
        network_id: string | null;
        expired: boolean;
      }>({
        url: `/v1/connections/shopify/by_nonce`,
        params: {
          nonce,
        },
      }).catch((error: unknown) => {
        if (isBaseError(error) && error.status === 404) {
          return null;
        }

        throw error;
      }),
  });

  const locationsQuery = useQuery({
    queryKey: [
      "ShopifyLocations",
      { nonce, networkId: network?.network_id, tenantId: network?.tenant_id },
    ],
    queryFn: () =>
      api<{
        data: Array<{
          id: number;
          name: string;
        }>;
      }>({
        url: `/v1/connections/shopify/locations`,
        params: {
          nonce,
        },
        headers: {
          "x-network-id": network?.network_id,
          "tenant-id": network?.tenant_id,
        },
      }),
    select: (response) => response.data,
    enabled: Boolean(nonce && network),
  });

  const locationOptions = useMemo(
    () =>
      locationsQuery.data?.map((location) => ({
        label: location.name,
        value: location.id,
      })) ?? [],
    [locationsQuery.data],
  );

  const isAdmin = useIsAdmin();

  const createShopifyConnectionMutation = useCreateShopifyConnection();

  const navigate = useNavigate();

  const toast = useToast();

  useBeforeUnload(
    useCallback(
      (event) => {
        if (connectionQuery.data?.expired) {
          return;
        }

        // Recommended
        event.preventDefault();

        // Included for legacy support, e.g. Chrome/Edge < 119
        event.returnValue = true;
      },
      [connectionQuery.data],
    ),
  );

  if (!nonce) {
    return <Navigate to="/" />;
  }

  // If this connection already has a network_id, it's already been configured
  if (connectionQuery.data?.network_id) {
    return (
      <Navigate
        to={`/distribution_networks/${connectionQuery.data.network_id}/connections/${connectionQuery.data.customer_connection_id}`}
        replace
      />
    );
  }

  if (connectionQuery.data?.expired) {
    return (
      <ErrorPage
        title="Oops..."
        description="Something went wrong with the installation process. Please retry the installation."
        primaryButton={
          <Button as={ReactRouterLink} to="/">
            Go home
          </Button>
        }
        secondaryButton={
          <Button
            variant="outline"
            colorScheme="gray"
            as={Link}
            href="https://apps.shopify.com/stord"
          >
            Try again
          </Button>
        }
        outsideNavigation
      />
    );
  }

  const formId = "connect-shopify-form";

  return (
    <Modal
      isOpen
      onClose={() => {}}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <form
        id={formId}
        onSubmit={handleSubmit(({ network, ...values }) => {
          createShopifyConnectionMutation.mutate(
            {
              nonce,
              ...network,
              ...values,
            },
            {
              onSuccess({ id }) {
                navigate(
                  `/distribution_networks/${network.network_id}/connections/${id}`,
                );
              },
              onError(error) {
                console.log("Error creating shopify connection", error);

                toast({
                  status: "error",
                  title:
                    "Sorry, there was an error. Please try again in a few minutes.",
                });
              },
            },
          );
        })}
        noValidate
      />

      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Shopify is almost connected!</ModalHeader>

        <ModalBody>
          <Flex direction="column" gap={4}>
            <Text>
              To complete your connection to Shopify, please provide the
              following details.
            </Text>

            {isAdmin ? (
              <Controller
                control={control}
                name="network"
                render={({ field, fieldState }) => (
                  <FormControl isInvalid={fieldState.invalid}>
                    <FormLabel>Network</FormLabel>

                    <AdminNetworkSelect
                      onChange={(networkId) => {
                        field.onChange(networkId);
                      }}
                    />

                    <FormErrorMessage>
                      {errors["network"]?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
                rules={{
                  required: "This field is required",
                }}
              />
            ) : (
              <NonAdminNetworkSelect control={control} />
            )}

            <RHFInput
              control={control}
              name="display_name"
              label="Connection name"
              isRequired
              rules={{
                required: "This field is required",
              }}
            />

            <RHFSelect
              name="source_location_id"
              label="Shopify inventory totals location"
              control={control}
              options={locationOptions}
              rules={{
                required: "This field is required",
              }}
              helperText="Stord will send all inventory data to this location in Shopify."
              placeholder="Select a Shopify location"
              isLoading={locationsQuery.isFetching}
              isDisabled={locationsQuery.isPending}
              isRequired
            />

            <Divider />

            <RHFSelect
              name="sales_channel"
              label="Map to sales channel"
              control={control}
              options={
                channelsQuery.data?.map((channel) => ({
                  label: channel.display_name,
                  value: channel.channel_id,
                })) ?? []
              }
              rules={{
                required: "This field is required",
              }}
              placeholder="Select an existing sales channel"
              helperText="This will replace any existing connection for this channel."
              isLoading={channelsQuery.isFetching}
              isDisabled={channelsQuery.isPending}
              isRequired
            />
          </Flex>
        </ModalBody>

        <ModalFooter gap={2}>
          <Button
            form={formId}
            type="submit"
            isLoading={createShopifyConnectionMutation.isPending}
          >
            Connect
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function AdminNetworkSelect({
  onChange,
}: {
  onChange: (network: { network_id: string; tenant_id: string }) => void;
}) {
  const [selectedNetwork, setSelectedNetwork] = useState<{
    network_id: string;
    name: string;
    tenant_id: string;
  } | null>(null);

  return (
    <NetworkSelect
      defaultOptions
      value={selectedNetwork}
      onChange={(newValue) => {
        setSelectedNetwork(newValue);
        onChange(newValue!);
      }}
      menuPortalTarget={document.body}
    />
  );
}

function NonAdminNetworkSelect({ control }: { control: Control<FieldValues> }) {
  const user = useUser();

  const [networks, networkOptions] = useMemo(() => {
    const networks: Array<{
      name: string;
      network_id: string;
      tenant_id: string;
    }> = [];

    for (const org of user.organizations) {
      for (const app of org.apps!) {
        for (const realm of app.realms) {
          if (app.alias === "oms") {
            networks.push({
              name: realm.name,
              network_id: realm.resource_id,
              tenant_id: org.id,
            });
          }
        }
      }
    }

    return [
      networks,
      networks.map(({ name, network_id }) => ({
        label: name,
        value: network_id,
      })),
    ] as const;
  }, [user]);

  if (networks.length === 1) {
    return <InvisibleNetworkSetter control={control} network={networks[0]} />;
  }

  return (
    <Controller
      control={control}
      name="network"
      rules={{
        required: "This field is required",
      }}
      render={({ field, fieldState }) => (
        <FormControl isRequired isInvalid={fieldState.invalid}>
          <FormLabel>Network</FormLabel>

          <Select
            options={networkOptions}
            value={networkOptions.find(
              (option) => option.value === field.value?.network_id,
            )}
            onChange={(option) => {
              const network = networks.find(
                (network) => network.network_id === option!.value,
              )!;

              field.onChange({
                network_id: network.network_id,
                tenant_id: network.tenant_id,
              });
            }}
          />

          <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
        </FormControl>
      )}
    />
  );
}

function InvisibleNetworkSetter({
  control,
  network,
}: {
  control: Control<FieldValues>;
  network: { name: string; network_id: string; tenant_id: string };
}) {
  const { field } = useController({
    control,
    name: "network",
  });

  useEffect(() => {
    if (field.value) {
      return;
    }

    field.onChange({
      network_id: network.network_id,
      tenant_id: network.tenant_id,
    });
  }, [field, network]);

  return null;
}

type ShopifyLocation = {
  location_name: string;
  source_location_id: string;
};

type ShopifyReturnType = {
  connector_id: string;
  details: {
    shop_name: string;
    shopify_connection_id: string;
    shopify_locations: ShopifyLocation[];
  };
  display_name: string;
  id: string;
  source: string;
};

function useCreateShopifyConnection() {
  const api = useApi();

  return useMutation({
    async mutationFn({
      nonce,
      tenant_id,
      network_id,
      display_name,
      source_location_id,
      sales_channel,
    }: {
      nonce: string;
      tenant_id: string;
      network_id: string;
      display_name: string;
      source_location_id: string;
      sales_channel: string;
    }) {
      // TODO: Sort out this madness. Why two api calls when one will do?
      const connection = await api.post<ShopifyReturnType>({
        url: "/v2/connections/shopify",
        body: {
          display_name,
          network_id,
          config: {},
          opts: {
            nonce,
          },
        },
        headers: {
          "tenant-id": tenant_id,
          "x-network-id": network_id,
        },
      });

      return api.patch<ShopifyReturnType>({
        url: `/v2/connections/${connection.id}`,
        body: {
          network_id,
          config: {
            location: { source_location_id },
            sales_channel: { channel_id: sales_channel },
          },
        },
        headers: {
          "tenant-id": tenant_id,
          "x-network-id": network_id,
        },
      });
    },
  });
}
