import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { fromZonedTime } from "date-fns-tz";

import { useApi, useDatadogRUM, qs } from "@stordco/fe-components";

import { useNetwork } from "../components/Network";
import type { Order } from "../features/Orders/types";

// Note: not 100% of these types - update if possible
type SearchServiceSearchFields = {
  network_id?: string;
  search_term?: string;
  search_field?: string;
  before?: number;
  after?: number;
  status?: string[];
  facility_id?: string[];
  type?: string;
  accepted_from_date?: string;
  accepted_to_date?: string;
  limit?: number;
  sort?: string;
  origin_facility_id?: string[];
  destination_facility_id?: string[];
};

export const useOrders = ({
  params,
  queryOptions = {},
}: {
  params: SearchServiceSearchFields;
  queryOptions?: Omit<
    UseQueryOptions<any, unknown, SearchServiceOrdersResponse>,
    "queryKey" | "queryFn"
  >;
}) => {
  const api = useApi();
  const network = useNetwork();
  const datadogRum = useDatadogRUM();
  const networkId = network.network_id;

  return useQuery({
    queryKey: [
      "orders",
      {
        ...params,
        networkId,
        timezone: network.timezone,
      },
    ],
    queryFn: () => {
      if (params.search_term) {
        datadogRum?.addAction("advanced_search:search", {
          value: params.search_term,
        });
      }

      return api.get<SearchServiceOrdersResponse>({
        url: formatOrderSearchUrl(
          "/v1/search/orders",
          { ...params, network_id: networkId },
          network.timezone,
        ),
        headers: {
          "x-timezone": network.timezone,
        },
      });
    },
    ...queryOptions,
  });
};

/** Format the url for doing an order search/export */
export function formatOrderSearchUrl(
  url: string,
  params: Record<string, any>,
  timezone: string,
) {
  return qs.stringifyUrl(
    {
      url,
      query: Object.entries(params).reduce(
        (modifiedParams, [paramName, paramValue]) => {
          if (/_start_date$/.test(paramName)) {
            // Convert start date to timestamp for start of that date in network timezone
            const date = fromZonedTime(paramValue as string, timezone);

            paramValue = date.toISOString();
          }

          if (/_end_date$/.test(paramName)) {
            // Convert end date to timestamp for start of next date in network timezone
            const date = fromZonedTime(paramValue as string, timezone);

            // Add one day
            date.setDate(date.getDate() + 1);
            paramValue = date.toISOString();
          }

          modifiedParams[paramName] = paramValue;

          return modifiedParams;
        },
        {} as Record<string, string>,
      ),
    },
    { arrayFormat: "bracket" },
  );
}

export type SearchServiceOrdersResponse = {
  data: Order[];
  metadata: Metadata;
  aggregations: {
    average_units_sold: number | null;
    revenue_by_currency: Record<
      string,
      {
        average_revenue: number;
        total_revenue: number;
      }
    >;
  };
};

interface Metadata {
  after: number | null;
  before: number | null;
  total_count: number;
}
