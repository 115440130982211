import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { attachPreloadErrorHandler } from "@stordco/fe-components";

import { App } from "./components/App";

import "./index.css";

attachPreloadErrorHandler();

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);
