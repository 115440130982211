import { Button } from "@chakra-ui/react";
import { faArrowLeft, faHouse } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";

import { FaIcon } from "../FaIcon";

import { ErrorPage } from "./ErrorPage";

export const NotFoundErrorPage = ({
  homeUrl = "/",
  outsideNavigation = false,
}: {
  homeUrl?: string;
  outsideNavigation?: boolean;
}) => {
  return (
    <ErrorPage
      badgeText="404 not found"
      title="Did you take a wrong turn?"
      description="You might be lost; this page doesn't seem to exist."
      secondaryButton={
        window.history.length > 1 ? (
          <Button
            variant="outline"
            colorScheme="gray"
            leftIcon={<FaIcon icon={faArrowLeft} />}
            onClick={() => {
              window.history.back();
            }}
          >
            Go back
          </Button>
        ) : undefined
      }
      primaryButton={
        <Button as={Link} leftIcon={<FaIcon icon={faHouse} to={homeUrl} />}>
          Return home
        </Button>
      }
      outsideNavigation={outsideNavigation}
    />
  );
};
