import { ErrorBoundary, type ErrorBoundaryProps } from "react-error-boundary";

import { datadogRum } from "@datadog/browser-rum";
import { isPreloadError } from "../../preloadError";
import { PageLoader } from "../PageLoader";
import { ErrorBoundaryPage } from "../ErrorPage";
import type { ComponentProps } from "react";

export function DatadogErrorBoundary({
  onError,
  errorBoundaryPageProps,
  ...props
}: Omit<
  ErrorBoundaryProps,
  "fallbackRender" | "fallback" | "FallbackComponent"
> & { errorBoundaryPageProps?: ComponentProps<typeof ErrorBoundaryPage> }) {
  return (
    <ErrorBoundary
      onError={(error, info) => {
        onError?.(error, info);

        if (!isPreloadError(error)) {
          datadogRum.addError(error, {
            scope: "error-boundary",
          });
        }
      }}
      fallbackRender={({ error }) =>
        isPreloadError(error) ? (
          <PageLoader />
        ) : (
          <ErrorBoundaryPage {...errorBoundaryPageProps} />
        )
      }
      {...props}
    />
  );
}
