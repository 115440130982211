import * as React from "react";
import { useDatePickerState } from "@react-stately/datepicker";
import type { AriaDatePickerProps } from "@react-aria/datepicker";
import { useDatePicker } from "@react-aria/datepicker";
import { useButton } from "@react-aria/button";
import type { AriaButtonProps } from "@react-aria/button";
import {
  FormLabel,
  InputGroup,
  Box,
  Button as ChakraButton,
  Popover,
  PopoverContent,
  PopoverBody,
  PopoverAnchor,
  useDisclosure,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import {
  today,
  getLocalTimeZone,
  type DateValue,
} from "@internationalized/date";
import { faCalendar, faXmark } from "@fortawesome/pro-regular-svg-icons";

import { FaIcon } from "../FaIcon";

import { Calendar, CalendarFooter } from "./Calendar";
import { DateField, StyledField } from "./DateField";

export function FieldButton(props: AriaButtonProps<"button">) {
  const ref = React.useRef<HTMLButtonElement>(null);
  const { buttonProps } = useButton(props, ref);
  return (
    <ChakraButton {...buttonProps} ref={ref} size="sm" h="1.75rem" mr="2">
      {props.children}
    </ChakraButton>
  );
}

export type DatePickerProps = AriaDatePickerProps<DateValue> & {
  setShouldCloseOnBlur?: (state: boolean) => void;
  hideLabel?: boolean;
  isClearable?: boolean;
  placeholder?: string;
};

export function DatePicker({
  setShouldCloseOnBlur,
  hideLabel = false,
  isClearable = true,
  placeholder,
  ...props
}: DatePickerProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const state = useDatePickerState({
    ...props,
    onChange: (value) => {
      props.onChange?.(value);
      onClose();
      if (setShouldCloseOnBlur) {
        setTimeout(() => {
          setShouldCloseOnBlur(true);
        }, 500);
      }
    },
  });
  const ref = React.useRef<HTMLDivElement>(null);
  const { groupProps, labelProps, fieldProps, calendarProps } = useDatePicker(
    {
      ...props,
      "aria-label": "Date picker",
    },
    state,
    ref,
  );

  React.useEffect(() => {
    if (isOpen && setShouldCloseOnBlur) {
      setShouldCloseOnBlur(false);
    }
  }, [isOpen, setShouldCloseOnBlur]);

  return (
    <Popover placement="bottom-start" isOpen={isOpen} onClose={onClose} isLazy>
      <Box
        position="relative"
        display="inline-flex"
        flexDirection="column"
        width="full"
      >
        {props.label && (
          <FormLabel {...labelProps} hidden={hideLabel}>
            {props.label}
          </FormLabel>
        )}
        <PopoverAnchor>
          <InputGroup
            {...groupProps}
            ref={ref}
            display="inline-flex"
            onClick={fieldProps.isDisabled ? undefined : onOpen}
          >
            <StyledField
              width="100%"
              justifyContent="space-between"
              borderColor={fieldProps.isDisabled ? "gray.100" : "gray.300"}
              _hover={fieldProps.isDisabled ? {} : { borderColor: "gray.400" }}
            >
              <DateField
                {...fieldProps}
                placeholder={placeholder}
                onOpen={onOpen}
              />

              {state.value ? (
                <InputRightElement>
                  <IconButton
                    variant="ghost"
                    colorScheme="gray"
                    aria-label="Clear"
                    icon={<FaIcon icon={faXmark} />}
                    size="xs"
                    onClick={(event) => {
                      event.stopPropagation();
                      state.setValue(null);
                    }}
                  />
                </InputRightElement>
              ) : (
                <InputRightElement boxSize="7">
                  <FaIcon
                    icon={faCalendar}
                    color={fieldProps.isDisabled ? "gray.400" : undefined}
                  />
                </InputRightElement>
              )}
            </StyledField>
          </InputGroup>
        </PopoverAnchor>
        <PopoverContent
          width="280px"
          _focus={{ outline: "none" }}
          aria-label="date picker"
          border="none"
          boxShadow="md"
        >
          <PopoverBody>
            <Calendar {...calendarProps} />
            <CalendarFooter>
              <CalendarFooter.ClearButton
                isClearable={isClearable}
                pickerState={state}
              />
              <ChakraButton
                aria-label="today"
                variant="outline"
                colorScheme="gray"
                flex="1"
                onClick={() => state.setDateValue(today(getLocalTimeZone()))}
              >
                Today
              </ChakraButton>
            </CalendarFooter>
          </PopoverBody>
        </PopoverContent>
      </Box>
    </Popover>
  );
}
