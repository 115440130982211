import { useCallback } from "react";
import { useQueries, useQuery, useQueryClient } from "@tanstack/react-query";

import { isBaseError, useApi } from "@stordco/fe-components";

import { useNetworkId } from "../components/Network";

export type ItemUnitConversion = {
  category: string;
  converted_unit_count: string;
  primary_count: string;
  status: "active" | "inactive";
  unit_conversion_id: string;
  unit_of_measure: string;
};

export type ItemHazmat = {
  hazard_class: string | null;
  limited_quantity: boolean | null;
  packing_group: "i" | "ii" | "iii" | null;
  proper_shipping_name: string | null;
  transport_mode:
    | "any"
    | "cargo_aircraft_only"
    | "ground"
    | "passenger_and_cargo_aircraft"
    | null;
  un_id: string | null;
  contains_lithium: boolean | null;
  battery_count: number | null;
  battery_installation: "installed" | "uninstalled" | null;
  lithium_ion_content: number | null;
};

export type ItemInternationalShipping = {
  tenant_id?: string;
  international_shipping_id?: string;
  item_id?: string;
  tariff_code: string;
  customs_value: string;
  customs_description: string;
  sale_cost_excluding_tax: string | null;
  currency_for_sale_cost: string | null;
  deleted_at?: string | null;
};

export type Barcode = {
  barcode_id?: string;
  type: BarcodeType;
  name: string;
  value: string;
};

export type MinimumStockLevelType = "units" | "weeks_of_supply";
export interface Item {
  auto_lot_locking_enabled: boolean;
  barcode: string | null;
  barcodes: Barcode[];
  brand: string | null;
  country_code: string;
  description: string | null;
  dimension_unit: string | null;
  height: string | null;
  image_urls: string[];
  inserted_at: string;
  integration_metadata: string | null;
  inventory_method: string | null;
  inventory_planning: boolean;
  minimum_stock_level: number | null;
  minimum_stock_level_unit: MinimumStockLevelType | null;
  is_digital: boolean;
  is_haz_mat: boolean;
  item_id: string;
  length: string | null;
  name: string;
  network_id: string;
  primary_unit: string;
  reorder_quantity: string;
  retail_price: string;
  sku: string;
  status: string;
  substitutable: boolean;
  track_by_exp_date: boolean;
  track_by_handling_unit: boolean;
  track_by_lot: boolean;
  unit_cost: string;
  upc: string;
  updated_at: string;
  weight: string | null;
  weight_unit: string | null;
  width: string | null;
  unit_conversions: ItemUnitConversion[];
  hazmat?: ItemHazmat | null;
  international_shipping?: ItemInternationalShipping | null;
  listings?: Array<Listing>;
  bundles: Array<{
    kit_id: string;
    kit_sku: string;
    name: string;
    description: string | null;
    // others, add as needed
  }>;
}

export interface Listing {
  listing: string;
  item_id: string;
  name: string;
  id: string;
  channel_id: string;
  channel_name: string | null;
  network_id: string;
  sku: string;
  updated_at: string;
}

export function useFetchItemDetails() {
  const networkId = useNetworkId();
  const api = useApi();

  return useCallback(
    (sku: string) =>
      api<{ data: Item }>(
        `/bff/v1/${networkId}/items/${encodeURIComponent(sku)}`,
      ).then((response) => response.data),
    [api, networkId],
  );
}

export function useCachedFetchItemDetails() {
  const queryClient = useQueryClient();
  const fetchItemDetails = useFetchItemDetails();

  return useCallback(
    (sku: string) =>
      queryClient.ensureQueryData({
        queryKey: ["itemDetails", { sku }],
        queryFn: () => fetchItemDetails(sku),
        staleTime: 1000 * 60 * 5,
      }),
    [fetchItemDetails, queryClient],
  );
}

export function useItemDetails({
  sku,
  ...options
}: {
  sku: string | undefined;
  enabled?: boolean;
}) {
  const fetchItemDetails = useFetchItemDetails();

  return useQuery({
    queryKey: ["itemDetails", { sku }],
    // TODO: Use `skipToken` after we upgrade react-query
    queryFn: () => fetchItemDetails(sku!),
    retry: (failureCount, error) => {
      if (isBaseError(error) && error.status === 404) {
        return false;
      }

      return failureCount < 3;
    },
    ...options,
  });
}

export function useItemsDetails({
  skus,
  ...options
}: {
  skus: string[];
  enabled?: boolean;
}) {
  const fetchItemDetails = useFetchItemDetails();

  return useQueries({
    queries: skus.map((sku) => {
      return {
        queryKey: ["itemDetails", { sku }],
        queryFn: () => fetchItemDetails(sku),
        ...options,
      };
    }),
  });
}

export const barcodeTypes = ["upc", "other"] as const;

export type BarcodeType = (typeof barcodeTypes)[number];

export const labelsByBarcodeType: Record<BarcodeType, string> = {
  upc: "UPC",
  other: "Other",
};
